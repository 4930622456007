<template>
  <BasePage>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <Container
      v-if="!loading"
      class="d-flex justify-center align-center"
      style="height: 82vh"
    >
      <div
        class="d-flex flex-column  align-center "
        style="width:100%;height: 50vh"
      >
        <!-- 
          v-if="
            payment_status == 'success' || payment_status == 'full_discount'
          "
       -->
        <v-alert
          v-if="
            userGetter.successStatuses.includes(payment_status) &&
              infoUpdatedSuccessfully
            // payment_status == 'success' || payment_status == 'full_discount'
          "
          type="success"
          class="body-1"
          outlined
          style="width: 60%"
          >{{ $t('successfullPayment') }}
          <router-link
            :to="
              ($route.query.typeId == 1 ? '/my-courses' : '/my-tests') +
                '?type=' +
                $route.query.typeId
            "
            >{{
              $t($route.query.typeId == 1 ? 'goToMyCourses' : 'goToMyTests')
            }}</router-link
          >
        </v-alert>

        <v-alert
          v-if="payment_status == 'faild'"
          type="error"
          class="body-1"
          outlined
          style="width: 60%"
          >{{ $t('faildPayment') }}
        </v-alert>
      </div>
    </Container>
  </BasePage>
</template>

<script>
import { mapGetters } from 'vuex';
import ApiService from '@/services/api.service';

export default {
  // props: ['id'],
  components: {},
  data() {
    return {
      loading: false,
      payment_status: this.$route.query.payment_status,
      infoUpdatedSuccessfully: false,
    };
  },
  methods: {
    async updateEnrollment() {
      try {
        this.loading = true;
        const {
          enrollmentId,
          payment_status,
          paymentId,
          id,
        } = this.$route.query;

        const enrollmentInfo = await ApiService.get(
          `items/enrollment/${enrollmentId}?fields=student_id`
        );

        if (
          this.userGetter.currentUser.id == enrollmentInfo.data.data.student_id
        ) {
          await ApiService.update(`items/enrollment`, enrollmentId, {
            payment_id: paymentId,
            payment_status,
            myfatoorah_id: id,
          });

          this.infoUpdatedSuccessfully = true;
          console.log('infoUpdatedSuccessfully');
        } else {
          console.log('not authorized');
        }

        // this.item = data.data[0];
        this.loading = false;
        // this.success = true;
      } catch (error) {
        console.log(error);
      }
    },
  },
  computed: {
    ...mapGetters(['userGetter']),
  },
  mounted() {
    this.updateEnrollment();
  },
};
</script>

<style></style>
